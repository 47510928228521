import {
    formatCurrency,
    getSinglarOrPluralWord,
    capitalizeString
} from "../../../../utilities/helpers";

import Form from "react-bootstrap/Form";

import ViewSwiper from "../ViewSwiper/ViewSwiper";
import { SheetTop } from "../SheetTop";
// import { AddSubtractQuantity } from "../AddSubtractQuantity";

import "./ticketDetails.scss";

export default function TicketDetails({
    toggleView,
    selectedObject,
    eventTicketLimit,
    changeQuickpickQuantity,
    allowTicketCountChange = false,
}) {

    const getIconClass = (selectedObject) => {
        if (selectedObject?.accessible) return "accessible";
        if (selectedObject?.GA) return "ticket";
        return "seat";
    }

    const iconClass = getIconClass(selectedObject)
    const selectionName = selectedObject?.GA
        ? selectedObject?.sectionName
        : `Sec ${selectedObject.sectionNumber} • Row ${selectedObject.rowNumber}`;
    const selectionDescription =
        selectedObject.offer?.description ||
        selectedObject?.listing?.offer?.description;

    return (
        <div className='vjx-td'>
            <SheetTop canGoBack={true} goBack={toggleView} />
            <ViewSwiper viewingSectionId={selectedObject?.sectionId} smallButtons={true} />
            <div className={`vjx-tdd ${iconClass}`}>
                <span className='vjx-tdd-s'>{capitalizeString(selectionName)}</span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px", paddingTop: '16px' }}>
                {allowTicketCountChange && (
                    <TicketCountChange
                        selectedObject={selectedObject}
                        changeQuickpickQuantity={changeQuickpickQuantity}
                        eventTicketLimit={eventTicketLimit}
                        quantity={selectedObject?.quantity}
                        isOfferDiscount={false}
                    />
                )}
            </div>
            {selectionDescription && (
                <div className='vjx-td-desc'>
                    <span className='vjx-td-desc-1'>Ticket description</span>
                    <span className='vjx-td-desc-2'>{selectionDescription}</span>
                </div>
            )}
        </div>
    );
}

// This is intended to be only used with quickpicks
function TicketCountChange({
    selectedObject,
    changeQuickpickQuantity,
    eventTicketLimit,
    quantity
}) {
    const selectionPrice = 10;
    const selectionType = selectedObject?.name;

    const getTicketOptions = () => {
        const availableCount = selectedObject?.seatIds.length;
        const listing = selectedObject?.listing;

        const maxAllowed =
            eventTicketLimit
                ? Math.min(eventTicketLimit, availableCount)
                : availableCount;

        if (listing) {
            return [Math.min(listing.quantity, maxAllowed)];
        }

        if (!selectedObject.offer) {
            return Array.from({ length: maxAllowed }, (_, index) => index + 1);
        }

        const max = Math.min(maxAllowed, selectedObject.offer.maxQuantity || maxAllowed);
        const offer = selectedObject.offer;

        if (offer.limit) {
            return [Math.min(offer.limit, max)];
        } else if (offer.maxQuantity) {
            return Array.from({ length: max }, (_, index) => index + 1);
        } else if (offer.minQuantity) {
            return Array.from(
                { length: Math.max(0, max - offer.minQuantity + 1) },
                (_, index) => index + offer.minQuantity
            ).filter((num) => num <= max);
        } else if (offer.multipleOf) {
            const options = [];
            for (let i = offer.multipleOf; i <= max; i += offer.multipleOf) {
                options.push(i);
            }
            return options;
        } else {
            return Array.from({ length: max }, (_, index) => index + 1);
        }
    };

    const options = getTicketOptions();

    return (
        <div className='vjx-tcc'>
            <div className='d-flex flex-column'>
                <span className='vjx-tcc-span'>{selectionType}</span>
                <p className='vjx-tcc-p'>
                    {formatCurrency(selectionPrice)} ea{" "}
                    <span className='vjx-tcc-p-span'>incl. fees</span>
                </p>
            </div>
            <Form.Select
                value={quantity}
                onChange={(e) => changeQuickpickQuantity(e.target.value)}
                aria-label='Default select example'
            >
                {options.map((number) => (
                    <option key={number} value={number}>
                        {number} {getSinglarOrPluralWord(number)}
                    </option>
                ))}
            </Form.Select>
        </div>
    );
}

{
    /* <Form.Select
                  value={quickpickQuantity}
                  onChange={(e) => changeQuickpickQuantity(e.target.value)}
                  aria-label='Default select example'
              >
                  {options.map((number) => (
                      <option key={number} value={number}>
                          {number} {getSinglarOrPluralWord(number)}
                      </option>
                  ))}
              </Form.Select> */
}
