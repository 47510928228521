import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"

import { ActionsProvider } from "../../../PurchaseFlow/NewSeatedPurchaseWrapper/providers/SeatmapProvider/SeatmapWrapper/providers/ActionsProvider";
import { usePackageSeatmap } from "../PackageSeatmapProvider";
import { SeatMap } from "../../../PurchaseFlow/NewSeatedPurchaseWrapper/providers/SeatmapProvider/SeatmapWrapper/SeatMap";

import { placePackageIntoCart } from "../../../../utilities/api";

import { TooltipProvider } from "../../../PurchaseFlow/NewSeatedPurchaseWrapper/providers/SeatmapProvider/SeatmapWrapper/providers/TooltipProvider";
import useSeatmapStore from "../../../../stores/seatmapStore";

export default function PackageSeatmapWrapper({ handleClose }) {
    // Zustand
    const setSeatLookupTable = useSeatmapStore(state => state.setSeatLookupTable);
    const setSectionLookupTable = useSeatmapStore(state => state.setSectionLookupTable);
    const selectedFromMap = useSeatmapStore(state => state.selectedFromMap);

    const navigate = useNavigate()
    const { eventPackage, setSeatedError } = usePackageSeatmap();

    const [mapError, setMapError] = useState(false);
    const [checkingAvailability, setCheckingAvailability] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [loadingComplete, setLoadingComplete] = useState(false);

    const checkout = () => {
        setCheckingAvailability(true);
        let data = {
            packageGroups: selectedFromMap,
            eventPackage: eventPackage,
        };

        placePackageIntoCart(data)
            .then((res) => {
                console.log("placePackageIntoCart res: ", res.data);
                sessionStorage.setItem(
                    "cart",
                    JSON.stringify({ cartId: res.data.cartId })
                );
                navigate(`/checkout`);
            })
            .catch((err) => {
                if (err.response) {
                    setSeatedError({
                        status: err.response.status,
                        title: "Selected tickets not available",
                        message: err.response.data.error.message || "An error occurred",
                        buttonText: "Return to tickets list",
                    });
                } else {
                    setSeatedError({
                        status: 500,
                        title: "Selected tickets not available",
                        message: "Network error occurred",
                        buttonText: "Return to tickets list",
                    });
                }
            })
            .finally(() => {
                setCheckingAvailability(false);
            });
    };

    const createSeatLookupTable = (ticketGroups, eventPackage) => {
        const { ticket_groups, ...packageWithoutTicketGroups } = eventPackage;
        const lookupTable = {};

        ticketGroups.forEach((ticketGroup) => {
            if (ticketGroup.GA === false) {
                // Create a single reference object for this ticket group
                const ticketGroupRef = {
                    ...ticketGroup,
                    resale: false,
                    package: packageWithoutTicketGroups, // Add package information to each group
                };

                // Use the same reference for all seats in this group
                ticketGroup.seatIds.forEach((seatId) => {
                    lookupTable[seatId] = ticketGroupRef;
                });
            }
        });

        return lookupTable;
    };

    const createSectionLookupTable = (ticketGroups, eventPackage) => {
        const { ticket_groups, ...packageWithoutTicketGroups } = eventPackage;
        const lookupTable = {};

        ticketGroups.forEach((ticketGroup) => {
            // Get the sectionId from the quickpick
            const { sectionId } = ticketGroup;

            // Initialize array for this section if it doesn't exist
            if (!lookupTable[sectionId]) {
                lookupTable[sectionId] = [];
            }

            // Add this quickpick to the array for this section with package info
            lookupTable[sectionId].push({
                ...ticketGroup,
                package: packageWithoutTicketGroups,
            });
        });

        return lookupTable;
    };

    useEffect(() => {
        // Calculate total price
        const newTotalPrice = selectedFromMap.map((ticketGroup) => {
            if (ticketGroup.GA) {
                return ticketGroup.quantity * ticketGroup.price;
            } else {
                return ticketGroup.price;
            }
        }).reduce((sum, price) => sum + price, 0);
        const seatedCount = selectedFromMap
            .filter((ticketGroup) => ticketGroup.GA === false)
            .map((ticketGroup) => ticketGroup.seatId).length;
        const gaCount = selectedFromMap
            .filter((ticketGroup) => ticketGroup.GA === true)
            .reduce((sum, ticketGroup) => sum + ticketGroup.quantity, 0);

        const newTotalCount = seatedCount + gaCount;
        setTotalCount(newTotalCount);
        setTotalPrice(newTotalPrice);
    }, [selectedFromMap]);

    useEffect(() => {
        let getMapAvailability = async () => {
            try {
                console.log("eventPackage: ", eventPackage);
                const packageGroups = eventPackage?.package_groups;
                if (!packageGroups) return;

                // Create lookup tables; allows for constant time checks
                const sectionLookupTable = createSectionLookupTable(
                    packageGroups,
                    eventPackage
                );
                setSectionLookupTable(sectionLookupTable);

                const seatLookupTable = createSeatLookupTable(
                    packageGroups,
                    eventPackage
                );        
                setSeatLookupTable(seatLookupTable);

                setLoadingComplete(true);
                console.log("LOADING COMPLETED....");
            } catch (error) {
                console.log("ERROR in getSectionAvailability: ", error);
                setMapError(true);
                setLoadingComplete(true);
            }
        };

        getMapAvailability();
    }, [eventPackage]);

    return (
        <ActionsProvider
            handleClose={handleClose}
            loadingComplete={loadingComplete}
            totalCount={totalCount}
            totalPrice={totalPrice}
            checkout={checkout}
            checkingAvailability={checkingAvailability}
        >
            <TooltipProvider>
                <SeatMap
                    loadingComplete={loadingComplete}
                    mapError={mapError}
                    setMapError={setMapError}
                    accessCodes={null}
                />
            </TooltipProvider>
        </ActionsProvider>
    );
}
