import { useEffect } from "react";

import { useTickets } from "../providers/TicketsProvider/TicketsProvider";
import { useModals } from "../providers/ModalsProvider/ModalsProvider";
import useSeatmapStore from "../../../../stores/seatmapStore";

import { Event } from "../../components/Event";
import { TicketSelectionPanel } from "./TicketSelectionPanel";

import "../../purchaseFlow.scss";
import "./newSeatedPurchaseContainer.scss";

export default function NewSeatedPurchaseContainer() {
    const { event, isLoading, isScheduled, scheduledTime, isMappingCreated } = useTickets();
    const { openCBModal } = useModals();

    // Zustand
    const getTicketImage = useSeatmapStore(state => state.getTicketImage);

    // Hide intercom widget on this page
    // This does hide it until you refresh the page / turn it back on again
    useEffect(() => {
        if (window.Intercom) {
            window.Intercom("update", {
                hide_default_launcher: true,
            });
        }
    }, []);

    return (
        <div
            className={` max-h-screen purchaseflow-gradient`}
        >
            <div className='d-flex-column nstc-ss'>
                <div className="pxccc">
                    <Event
                        event={event}
                        openModal={openCBModal}
                    />
                </div>
                <TicketSelectionPanel
                    isLoading={isLoading}
                    isMappingCreated={isMappingCreated}
                    isScheduled={isScheduled}
                    scheduledTime={scheduledTime}
                    seatmapPreview={getTicketImage(event?.venue?.slug, 'preview', 'preview')}
                />
            </div>
        </div>
    );
}
