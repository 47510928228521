import { useContext, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

import LoadingContext from "../../../../context/Loading/Loading";
import { useModals } from "../providers/ModalsProvider";

import { getPaymentIntent, getCart } from "../../../../utilities/api";

import { sort } from "../../../../utilities/helpers";

import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import { BackButton } from "../../../BackButton";
import { PageLoadingContainer } from "../../../PageLoadingContainer";
import { PaymentMethod } from "../components/PaymentMethod";
import { Terms } from "../components/Terms";
import { BuyingThis } from "../../components/BuyingThis";
import { AdditionalInfo } from "../../components/AdditionalInfo";
import { Event } from "../../components/Event";

import "../../purchaseFlow.scss";
import "./newCheckoutContainer.scss";
import useSeatmapStore from "../../../../stores/seatmapStore";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function NewCheckoutContainer() {
  // Zustand
  const getTicketImage = useSeatmapStore((state) => state.getTicketImage);

  const { isLoading, showLoading, hideLoading } = useContext(LoadingContext);
  const { openModal, startCheckoutTimer } = useModals();

  const [clientSecret, setClientSecret] = useState("");

  const [status, setStatus] = useState("checkout");

  const [intentId, setIntentId] = useState("");

  const [event, setEvent] = useState();

  const [order, setOrder] = useState();

  const [cart, setCart] = useState(null);

  useEffect(() => {
    showLoading();

    let cart = sessionStorage.getItem("cart");
    if (cart) cart = JSON.parse(cart);

    let cartId;
    if (cart?.cartId) {
      cartId = cart.cartId;
    }

    getCart(cartId).then((res) => {
      console.log("cart: ", res.data);
      setCart(res.data);

      // Start 10min period to checkout or be kicked out...
      if (res.data?.itemsAdded) {
        startCheckoutTimer(res.data?.itemsAdded);
      }

      const event = res.data.package
        ? sort(res.data.package.events)[0]
        : res.data.event;
      let data = {
        ip: res.data.ipAddress,
        carted: true,
        cartTickets: res.data.tickets,
        totalFromCart: res.data.total,
        event: event,
      };

      setEvent(event);
      getPaymentIntent(data)
        .then((res) => {
          setClientSecret(res.data.client_secret);
          setIntentId(res.data.id);
          hideLoading();
        })
        .catch((err) => {
          console.error("err: ", err);
          // console.error(err)
          hideLoading();
        });
    });
  }, []);

  // Hide intercom widget on this page
  // This does hide it until you refresh the page / turn it back on again
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    }
  }, []);

  const addOns = [];

  const appearance = {
    theme: "flat",
    variables: {
      fontFamily: ' "Poppins", sans-serif',
      fontLineHeight: "1.4",
      fontSizeBase: "14px",
      fontSizeSm: "12px",
      fontWeightNormal: "500",
      borderRadius: "12px",
      colorPrimary: "#5ab6f8",
      colorBackground: "#FFFFFF",
      colorText: "#23262F",
      colorWarning: "#EF466F",
      colorDanger: "#EF466F",
      colorTextSecondary: "#777e90",
      spacingUnit: "12px",
      spacingGridRow: "24px",
    },
    rules: {
      ".Input": {
        boxShadow: "0px 0px 0px 2px #E6E8EC",
        padding: "12.250px 14.875px",
        lineHeight: "var(--fontLineHeight)",
      },

      ".Input:focus": {
        outline: "0",
        boxShadow: "0px 0px 0px 2px var(--colorPrimary)",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray",
      },
      ".Tab": {
        boxShadow: "0px 0px 0px 2px #E6E8EC",
        padding: "12.250px 14.875px",
        border: "none",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        border: "none",
        boxShadow: "0px 0px 0px 2px var(--colorPrimary)",
        backgroundColor: "var(--colorPrimary)",
      },
      ".Label": {
        fontWeight: "700",
        textTransform: "uppercase",
        color: "var(--colorTextSecondary)",
        marginBottom: "var(--spacingUnit)",
        lineHeight: "var(--fontLineHeight)",
      },

      ".Input--invalid": {
        boxShadow:
          "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
      },

      ".Error": {
        marginTop: "var(--spacingUnit)",
      },
    },
  };

  const options = {
    // passing the client secret obtained from the server
    clientSecret,
    appearance,
    loader: "always",
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false,
    },
  };

  const handleGoBack = () => {
    openModal("Are you sure?", event);
  };

  return (
    <>
      {isLoading ? (
        <PageLoadingContainer
          text='Securing your tickets'
          subtext='You will be redirected to the payment page.'
          showLoader={false}
          icon='ticket'
        />
      ) : (
        <div className='new-checkout-page purchaseflow-gradient purchaseflow-gradient--tablet-desktop-only'>
          <div className='desktop-only'>
            <BackButton handleGoBack={handleGoBack} />
          </div>
          <div className='pfselect-wrapper'>
            <div className='tablet-desktop-only'>
              <Event
                eventPackage={cart?.package}
                event={event}
                openModal={openModal}
              />
            </div>
            <Stack
              direction='horizontal'
              className='bg-white pt-2 px-3 mobile-only'
            >
              <Button
                variant='default'
                size='sm'
                className='btn--icon flex-shrink-0 mobile-only border-0 w-auto'
                onClick={handleGoBack}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  role='img'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M15.7071 19.2071C16.0976 18.8166 16.0976 18.1834 15.7071 17.7929L9.91421 12L15.7071 6.20711C16.0976 5.81658 16.0976 5.18342 15.7071 4.79289C15.3166 4.40237 14.6834 4.40237 14.2929 4.79289L8.5 10.5858C7.71895 11.3668 7.71895 12.6332 8.5 13.4142L14.2929 19.2071C14.6834 19.5976 15.3166 19.5976 15.7071 19.2071Z'
                    fill='#777E91'
                  />
                </svg>
              </Button>
              <span className='flex-grow-1 text-center caption-bold'>
                Checkout
              </span>
            </Stack>
            <Card className='card-xl card-xl--dark great-wrapper'>
              <Card.Body className='wrapper-body pt-0 pt-md-4'>
                <div className='card-body-heading g-texts tablet-desktop-only'>
                  <Card.Title as='h5'>You're so close!</Card.Title>
                  <Card.Subtitle as='h6'>
                    Select your payment method and complete your purchase.
                  </Card.Subtitle>
                </div>
                <BuyingThis
                  cart={cart}
                  event={event}
                  getTicketImage={getTicketImage}
                />
                <PaymentMethod
                  clientSecret={clientSecret}
                  stripePromise={stripePromise}
                  options={options}
                  status={status}
                  event={event}
                  addOns={addOns}
                  setStatus={setStatus}
                  intentId={intentId}
                  order={order}
                  cart={cart}
                />
                <Terms />
                <AdditionalInfo
                  bg='light'
                  MTDescription={"Securely stored in your account."}
                  BPDescription={"Safe from bots and scalpers."}
                />
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </>
  );
}


{/* <AboutSection
    title='Ticket information'
    summary={event?.offers?.find(offer => offer.id === cart?.tickets[0]?.associatedOfferId)?.description}
    bg='light'
/> */}