import React, {
  createContext,
  useContext,
  useRef,
  useState,
} from "react";

import "./tooltipProvider.scss";
import { useActions } from "../ActionsProvider/ActionsProvider";
import { TooltipWrapper } from "./TooltipWrapper";

// Create the context
const TooltipContext = createContext(undefined);

export const useTooltips = () => {
  const context = useContext(TooltipContext);
  if (!context) {
    throw new Error("Context must be used within a TooltipProvider");
  }
  return context;
};

// Create the provider component
export const TooltipProvider = ({ children }) => {
  const ARROW_SIZE = 20;
  const [selectedSeatId, setSelectedSeatId] = useState(null);
  const [ticketGroup, setTicketGroup] = useState(null);
  const [ticketGroupPlural, setTicketGroupPlural] = useState([]);
  const [price, setPrice] = useState(0);

  const { containerRef } = useActions();
  const [tipContentType, setTipContentType] = useState(-1);
  const [tipPosition, setTipPosition] = useState({
    vertical: "top",
    horizontal: "center",
  });

  const dialogRef = useRef(null);
  const timer = useRef(null);
  const initialTimestamp = useRef(null);

  const hideTips = () => {
    const dialog = dialogRef?.current;
    if (dialog) {
      dialog.close();
    }
  };

  const calculateVisibleArea = (left, top, width, height, container) => {
    const right = left + width;
    const bottom = top + height;

    const visibleLeft = Math.max(0, left);
    const visibleTop = Math.max(0, top);
    const visibleRight = Math.min(container.width, right);
    const visibleBottom = Math.min(container.height, bottom);

    return (
      Math.max(0, visibleRight - visibleLeft) *
      Math.max(0, visibleBottom - visibleTop)
    );
  };

  const calculateBestPosition = (
    targetRect,
    container,
    tooltipWidth,
    tooltipHeight
  ) => {
    const positions = [
      { side: "top", align: "center" },
      { side: "bottom", align: "center" },
      { side: "right", align: "center" },
      { side: "left", align: "center" },
    ];

    let bestPosition = null;
    let maxVisibleArea = -1;

    for (const pos of positions) {
      const { left, top } = calculatePositionCoordinates(
        pos.side,
        targetRect,
        container,
        tooltipWidth,
        tooltipHeight
      );
      const visibleArea = calculateVisibleArea(
        left,
        top,
        tooltipWidth,
        tooltipHeight,
        container
      );

      if (visibleArea > maxVisibleArea) {
        maxVisibleArea = visibleArea;
        bestPosition = { ...pos, left, top };
      }

      if (visibleArea === tooltipWidth * tooltipHeight) {
        return bestPosition;
      }
    }

    return bestPosition;
  };

  const calculatePositionCoordinates = (
    side,
    targetRect,
    container,
    tooltipWidth,
    tooltipHeight
  ) => {
    let left, top;

    switch (side) {
      case "top":
        top = targetRect.top - container.top - tooltipHeight - ARROW_SIZE;
        left =
          targetRect.left -
          container.left +
          (targetRect.width - tooltipWidth) / 2;
        break;
      case "right":
        left = targetRect.right - container.left + ARROW_SIZE;
        top =
          targetRect.top -
          container.top +
          (targetRect.height - tooltipHeight) / 2;
        break;
      case "bottom":
        top = targetRect.bottom - container.top + ARROW_SIZE;
        left =
          targetRect.left -
          container.left +
          (targetRect.width - tooltipWidth) / 2;
        break;
      case "left":
        left = targetRect.left - container.left - tooltipWidth - ARROW_SIZE;
        top =
          targetRect.top -
          container.top +
          (targetRect.height - tooltipHeight) / 2;
        break;
    }

    return { left, top };
  };

  const showTooltip = (target, container, tooltip) => {
    if (!tooltip || !container) return;

    tooltip.style.visibility = "hidden";
    tooltip.style.display = "block";

    setTimeout(() => {
      const containerRect = container.getBoundingClientRect();
      const tooltipWidth = parseInt(tooltip.clientWidth);
      const tooltipHeight = parseInt(tooltip.clientHeight);

      let targetRect;
      if (target instanceof Element) {
        // For seat-based positioning
        targetRect = target.getBoundingClientRect();
      } else if (target.clientX !== undefined && target.clientY !== undefined) {
        // For event-based positioning ( click a section etc )
        const absoluteX = target.clientX;
        const absoluteY = target.clientY;

        targetRect = {
          left: absoluteX,
          top: absoluteY,
          right: absoluteX,
          bottom: absoluteY,
          width: 0,
          height: 0,
        };
      } else {
        const absoluteX = target.x - window.scrollX;
        const absoluteY = target.y - window.scrollY;
        // For coordinate-based positioning ( hover on section etc )
        targetRect = {
          left: absoluteX,
          top: absoluteY,
          right: absoluteX,
          bottom: absoluteY,
          width: 0,
          height: 0,
        };
      }

      const bestPosition = calculateBestPosition(
        targetRect,
        containerRect,
        tooltipWidth,
        tooltipHeight
      );

      if (bestPosition) {
        setTipPosition({
          vertical: bestPosition.side,
          horizontal: bestPosition.align,
        });
        setPositionAndStyle(tooltip, bestPosition.left, bestPosition.top);
      }

      if (initialTimestamp) {
        initialTimestamp.current = null; // Reset
      }
    }, 0);
  };

  const showTooltipFromSeat = (
    seat,
    seatId,
    newTipContentType,
    ticketGroup,
    simulatedInventory
  ) => {
    setSelectedSeatId(seatId);
    // Can show 2 different tips, with different content
    if (newTipContentType === 5) {
      setTicketGroupPlural(simulatedInventory);
    } else {
      setTicketGroup(ticketGroup);
    }

    setTipContentType(newTipContentType);

    const tooltip = dialogRef.current;
    const container = containerRef.current;
    if (!seat || !tooltip || !container) return;

    showTooltip(seat, container, tooltip);
  };

  const showTooltipFromEvent = (event, newTipContentType, sectionGroups) => {
    setTicketGroupPlural(sectionGroups);
    setTipContentType(newTipContentType);

    const tooltip = dialogRef.current;
    const container = containerRef.current;
    if (!tooltip || !container) return;

    showTooltip(event, container, tooltip);
  };

  const showTooltipFromCoordinates = (x, y, newTipContentType, price) => {
    // Dont show if already showing offerToolTip
    if (tipContentType === 4 && dialogRef?.current?.open) {
      console.log("TIPCONTENTTYPE 4, NOT SHOWING PRICE");
      return;
    }

    setPrice(price);
    setTipContentType(newTipContentType);

    const tooltip = dialogRef.current;
    const container = containerRef.current;
    if (!tooltip || !container) return;

    showTooltip({ x, y }, container, tooltip);
  };

  const setPositionAndStyle = (ref, x, y) => {
    if (ref) {
      ref.style.left = `${x}px`;
      ref.style.top = `${y}px`;
      ref.style.visibility = "";
      ref.style.display = "";
      ref.show();
    }
  };

  const clearPriceTipTimeout = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (initialTimestamp) {
      initialTimestamp.current = null; // Reset
    }
  };

  const closeIfSpecificTooltip = (closeIfType) => {
    if (closeIfType === 3) {
      clearPriceTipTimeout();
    }
    if (tipContentType === closeIfType) {
      hideTips();
    }
  };

  return (
    <TooltipContext.Provider
      value={{
        price,
        initialTimestamp,
        hideTips,
        selectedSeatId,
        ticketGroupPlural,
        timer,
        clearPriceTipTimeout,
        ticketGroup,
        showTooltipFromCoordinates,
        showTooltipFromEvent,
        showTooltipFromSeat,
        closeIfSpecificTooltip
      }}
    >
      {children}
      <TooltipWrapper
        dialogRef={dialogRef}
        tipPosition={tipPosition}
        tipContentType={tipContentType}
      />
    </TooltipContext.Provider>
  );
};
