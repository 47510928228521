import React, { useState } from "react";

import { formatCurrency } from "../../../../../../../../../../utilities/helpers";

import Button from "react-bootstrap/Button";
import { useTooltips } from "../../TooltipProvider";
import { AddSubtractQuantity } from "../../../../../../../../components/AddSubtractQuantity";
import { useActions } from "../../../ActionsProvider";
import { useUnlock } from "../../../../../../UnlockProvider/UnlockProvider";
import useSeatmapStore from "../../../../../../../../../../stores/seatmapStore";

//
// If its complicated; its most likely dog-shit
// like this code
//
export default function OfferTooltip({ isSingleSeat }) {
  // Zustand store
  const selectGASeats = useSeatmapStore(state => state.selectGASeats);

  const { selectedSeatId, ticketGroupPlural, hideTips, ticketGroup } =
    useTooltips();
  const [offerQuantities, setOfferQuantities] = useState({});
  const { selectSpecificSeat } = useActions();

  const handleSelectSpecificSeat = (offer) => {
    const inventoryType = ticketGroup.offer.inventoryType;
    let modifiedTicketGroup = { ...ticketGroup, offer: { ...offer, inventoryType } };
    selectSpecificSeat(selectedSeatId, modifiedTicketGroup);
    hideTips();
  };

  const getQuantityStep = (offer, currentQuantity) => {
    // If there's a limit, we either go to the limit or 0
    if (offer.limit) {
      return offer.limit; // Either jump to limit or back to 0
    }

    if (offer.minQuantity && currentQuantity < offer.minQuantity) {
      return offer.minQuantity;
    }

    // If there's a multipleOf constraint
    if (offer.multipleOf) {
      return offer.multipleOf;
    }

    // Default step size is 1
    return 1;
  };

  // Utility function to validate the new quantity against constraints
  const validateNewQuantity = (newQuantity, offer) => {
    // Don't go below 0
    if (newQuantity < 0) {
      return 0;
    }

    // Special handling for limit: only allow 0 or exactly the limit value
    if (offer.limit) {
      // If trying to set any value other than 0 or the limit, return null
      if (newQuantity !== 0 && newQuantity !== offer.limit) {
        return null;
      }
      return newQuantity;
    }

    // Check max quantity constraint
    if (offer.maxQuantity && newQuantity > offer.maxQuantity) {
      return null; // Indicates invalid quantity
    }

    // Check minimum quantity constraint
    if (
      offer.minQuantity &&
      newQuantity < offer.minQuantity &&
      newQuantity !== 0
    ) {
      return 0;
    }

    return newQuantity;
  };

  const increment = (offer) => {
    const currentQuantity = offerQuantities[offer.id] || 0;
    const step = getQuantityStep(offer, currentQuantity);
    const newQuantity = validateNewQuantity(currentQuantity + step, offer);

    if (newQuantity !== null) {
      updateOfferQuantity(offer.id, newQuantity);
    }
  };

  const decrement = (offer) => {
    const currentQuantity = offerQuantities[offer.id] || 0;
    const step = getQuantityStep(offer, currentQuantity);
    const newQuantity = validateNewQuantity(currentQuantity - step, offer);

    if (newQuantity !== null) {
      updateOfferQuantity(offer.id, newQuantity);
    }
  };

  const updateOfferQuantity = (offerId, quantity) => {
    // incorrect for offer_discounts
    setOfferQuantities((prev) => ({
      ...prev,
      [offerId]: quantity,
    }));
  };

  const selectAndHide = () => {
    hideTips();
    const selectedGroups = ticketGroupPlural.reduce((acc, ticketGroup) => {
      const offer = ticketGroup?.offer || ticketGroup?.package;
      if (offer && offerQuantities[offer.id] > 0) {
        acc.push({
          quantity: offerQuantities[offer.id],
          ...ticketGroup
        });
      }
      return acc;
    }, []);

    selectGASeats(selectedGroups);
  };

  const totalQuantity = Object.values(offerQuantities).reduce(
    (a, b) => a + b,
    0
  );

  const renderOffer = (inventory, offer, invIndex, isDiscount = false) => {
    if (!offer) return null;

    const uniqueKey = `${inventory.id}-${offer.id}-${invIndex}${
      isDiscount ? "-discount" : ""
    }`;

    const mainOfferElement =
      offer?.accessCode && !offer.unlocked ? (
        <LockedOffer key={uniqueKey} offer={offer} />
      ) : (
        <OfferOpen
          key={uniqueKey}
          offer={offer}
          inventory={inventory}
          isSingleSeat={isSingleSeat}
          quantity={offerQuantities[offer.id] || 0}
          increment={() => increment(offer)}
          decrement={() => decrement(offer)}
          selectSpecificSeat={() => handleSelectSpecificSeat(offer)}
          isDiscount={isDiscount}
        />
      );

    if (!offer.offer_discounts?.length) {
      return mainOfferElement;
    }

    return (
      <div key={uniqueKey} className='d-flex d-flex-column gap-2'>
        {mainOfferElement}
        {/* <div className='d-flex d-flex-column gap-2'>
          {offer.offer_discounts.map((discountOffer, discountIndex) =>
            renderOffer(
              inventory,
              discountOffer,
              `${invIndex}-${discountIndex}`,
              true
            )
          )}
        </div> */}
      </div>
    );
  };

  return (
    <div className='GATooltip'>
      <div className='GATop'>Section</div>
      <div className='GABody'>
        <div className='GADesc'>
          <p>
            General Admission <br />
            Standing Room
          </p>
        </div>
        {ticketGroupPlural.map((inventory, invIndex) => {
          if (!inventory?.offer && !inventory?.package) return null;

          const offer = inventory?.offer || inventory?.package;
          return renderOffer(inventory, offer, invIndex);
        })}
        {!isSingleSeat && (
          <div className='GABRow'>
            <div className='GABT'>
              <p className='CountP'>{totalQuantity}</p>
              <p>{totalQuantity > 1 ? "Tickets" : "Ticket"}</p>
            </div>
            <Button
              variant='secondary'
              size='lg'
              className='GAButton'
              onClick={selectAndHide}
              disabled={totalQuantity === 0}
            >
              Add now
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function OfferOpen({
  inventory,
  offer,
  isSingleSeat,
  selectSpecificSeat,
  quantity,
  increment,
  decrement,
}) {
  return (
    <div className='GARow'>
      <div className='GAVSection'>
        <p className='GAVH'>{offer.name}</p>
        <p className='GAVP'>{formatCurrency(inventory.price)} ea</p>
        <p className='GAVL'>Incl. Taxes & Fees</p>
      </div>
      {isSingleSeat ? (
        <Button
          variant='secondary'
          size='sm'
          className='TooltipButton'
          onClick={selectSpecificSeat}
        >
          Add now
        </Button>
      ) : (
        <AddSubtractQuantity
          whiteBG={false}
          quantity={quantity}
          increment={increment}
          decrement={decrement}
        />
      )}
    </div>
  );
}

function LockedOffer({ offer }) {
  const { openUnlockModal } = useUnlock();

  const clickUnlock = () => {
    openUnlockModal(offer);
  };

  return (
    <div className='LRow'>
      <div className='LRowL'>
        <svg
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 9.16667C8.52285 9.16667 7.20703 9.22047 6.13198 9.2888C5.11226 9.35362 4.32816 10.1316 4.25498 11.1442C4.20292 11.8644 4.16667 12.6234 4.16667 13.3333C4.16667 14.0433 4.20292 14.8022 4.25498 15.5225C4.32816 16.535 5.11226 17.313 6.13198 17.3779C7.20703 17.4462 8.52285 17.5 10 17.5C11.4772 17.5 12.793 17.4462 13.868 17.3779C14.8877 17.313 15.6718 16.535 15.745 15.5225C15.7971 14.8022 15.8333 14.0433 15.8333 13.3333C15.8333 12.6234 15.7971 11.8644 15.745 11.1442C15.6718 10.1316 14.8877 9.35362 13.868 9.2888C12.793 9.22047 11.4772 9.16667 10 9.16667ZM6.02625 7.62549C4.17813 7.74297 2.72614 9.17698 2.59265 11.024C2.53876 11.7695 2.5 12.5708 2.5 13.3333C2.5 14.0959 2.53876 14.8972 2.59265 15.6427C2.72614 17.4897 4.17813 18.9237 6.02625 19.0412C7.13148 19.1114 8.48288 19.1667 10 19.1667C11.5171 19.1667 12.8685 19.1114 13.9737 19.0412C15.8219 18.9237 17.2739 17.4897 17.4074 15.6427C17.4612 14.8972 17.5 14.0959 17.5 13.3333C17.5 12.5708 17.4612 11.7695 17.4074 11.024C17.2739 9.17698 15.8219 7.74297 13.9737 7.62549C12.8685 7.55524 11.5171 7.5 10 7.5C8.48288 7.5 7.13148 7.55524 6.02625 7.62549Z'
            fill='#777E91'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.8333 13.9434C11.3315 13.6552 11.6666 13.1166 11.6666 12.4997C11.6666 11.5792 10.9205 10.833 9.99998 10.833C9.07951 10.833 8.33331 11.5792 8.33331 12.4997C8.33331 13.1166 8.66848 13.6552 9.16665 13.9434V14.9997C9.16665 15.4599 9.53974 15.833 9.99998 15.833C10.4602 15.833 10.8333 15.4599 10.8333 14.9997V13.9434Z'
            fill='#777E91'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M5.83331 4.99967C5.83331 2.69849 7.69879 0.833008 9.99998 0.833008C12.3012 0.833008 14.1666 2.69849 14.1666 4.99967V8.33301C14.1666 8.79325 13.7936 9.16634 13.3333 9.16634C12.8731 9.16634 12.5 8.79325 12.5 8.33301V4.99967C12.5 3.61896 11.3807 2.49967 9.99998 2.49967C8.61927 2.49967 7.49998 3.61896 7.49998 4.99967V8.33301C7.49998 8.79325 7.12688 9.16634 6.66665 9.16634C6.20641 9.16634 5.83331 8.79325 5.83331 8.33301V4.99967Z'
            fill='#777E91'
          />
        </svg>
        <span className='LRowS'>{offer.name}</span>
      </div>
      <button className='LRowB' onClick={clickUnlock}>
        Unlock
      </button>
    </div>
  );
}
