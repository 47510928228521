import React from 'react';

import { sort, formatDateTime, getStartDateFormatter, timezones, getTimezoneDate, getUrl } from '../../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import { IconButton } from '../../IconButton';
import { LoadingContainer } from '../../LoadingContainer';

import placeholder from '../../../assets/placeholder.png'

import './lists.scss';

export default function UpcomingEventsList({ venue }) {

    const timezone = timezones(venue?.timezone)

    return (
        <Stack as="ul">
            {venue && sort(venue.allEvents).map((event, index) => {
                return (
                    <Card key={index} body className='item card-xs card--light' as="li">
                        <Stack direction="horizontal" gap={3}>
                            <Card.Img
                                src={event?.image?.url || placeholder}
                                alt={`Cover art for ${event?.name} event`}
                                width="100"
                                height="100"
                                className="event-image"
                            />
                            <div className="event-info d-flex-column flex-lg-row gap-2 align-items-lg-center ">
                                <div className="event-name-date-wrapper">
                                    <p className="event-name">{event?.name}</p>
                                    <div>
                                        <span className="fw-semi-bold text-muted small">{formatDateTime(getTimezoneDate(event?.start, timezone), getStartDateFormatter(event))}</span>
                                    </div>
                                </div>
                                <IconButton
                                    link={getUrl(`/e/${event?.seoUrl}/${event?.shortCode}`, event?.seatmap?.ga_only)}
                                    variant="outline-light"
                                    btn="ticket--primary"
                                    styles="align-self-start ms-lg-auto text-primary mt-0">
                                    Get Tickets
                                </IconButton>
                            </div>
                        </Stack>
                    </Card>
                )
            })}

            {/* <LoadingContainer /> */}
        </Stack>
    );
}
