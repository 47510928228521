import React, { Fragment, useState, useEffect } from 'react';

import { formatDateTime, getStartDateFormatter, formatCurrency, capitalizeString, formatShortAddress, getTimezoneDate, timezones, removeDuplicateTickets, getSinglarOrPluralWord } from '../../../../../../utilities/helpers';

import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import ListGroup from 'react-bootstrap/ListGroup';

import logo from '../../../../../../assets/logo.svg';

import './invoice.scss';

export default function Invoice({ invoice, ticketAmount, totalAmount, facilityFee, serviceFee, processingFee, tax, totalFees }) {

    const [uniqueTickets, setUniqueTickets] = useState([])

    let timezone = timezones(invoice?.timezone?.abbr)

    const isPackage = invoice?.package

    // event or package
    const obj = invoice?.event || invoice?.package

    // remove duplicate tickets in package to display only unique ones
    useEffect(() => {
        let tickets = [];
        if (isPackage) {
            tickets = removeDuplicateTickets(invoice?.tickets)
        } else {
            tickets = invoice?.tickets
        }
        setUniqueTickets(tickets)
    }, [invoice, isPackage])

    return (
        <div id="invoice" className='docs-container'>
            <header className="d-flex">
                <div className='col'>
                    <Image
                        src={logo}
                        className="mb-4"
                        alt="blocktickets"
                    />
                </div>
                <div className='col'>
                    <Stack className="align-items-end fw-semi-bold">
                        <small className='text-uppercase d-block'>Invoice</small>
                        <small className='text-muted tiny fw-normal'>This is not a ticket. This cannot be used for entry.</small>
                    </Stack>
                </div>
            </header>
            <div className="mt-4">
                <div className="mb-4 invoice-header">
                    <div className="col mb-4">
                        <h2 className='text-muted mb-2 caption'>{isPackage ? 'Package' : 'Event'}</h2>
                        <ul>
                            <li>
                                <p className='event-name'><strong>{obj?.name}</strong></p>
                                <ul className='caption'>
                                    {isPackage && (
                                        <li>{obj?.events?.length} {getSinglarOrPluralWord(obj?.events?.length, 'Event')}</li>
                                    )}
                                    <li>{formatDateTime(getTimezoneDate(obj?.start, timezone), getStartDateFormatter(invoice?.event))}</li>
                                    <li>{capitalizeString(obj?.venue?.name)}</li>
                                    <li>{formatShortAddress(obj?.venue)}</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="col">
                        <h2 className='text-muted mb-2 caption'>Attendee</h2>
                        <ul>
                            <li>
                                <span><strong>{capitalizeString(invoice?.users_permissions_user?.firstName)} {capitalizeString(invoice?.users_permissions_user?.lastName)}</strong></span>
                                <ul className="caption">
                                    <li>Payment information: {capitalizeString(invoice?.intentDetails?.charges?.data[0]?.payment_method_details?.card?.brand)}</li>
                                    <li>Charge: {formatCurrency(invoice?.intentDetails?.charges?.data[0]?.amount / 100)}</li>
                                    <li>Last 4 digits: {invoice?.intentDetails?.charges?.data[0]?.payment_method_details?.card?.last4}</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='mb-4'>
                    <p><span className='caption caption-bold'>Order No.: {invoice?.orderId}</span></p>
                    <p><span className='caption caption-bold'>Order Date: {formatDateTime(getTimezoneDate(invoice?.processedAt, timezone))}</span></p>
                </div>
                <Card body>
                    <h1 className='caption caption-bold mb-3'>Ticket/s Details</h1>
                    {uniqueTickets?.length > 0 && (
                        <ul className="ticket-details">
                            {uniqueTickets[0]?.generalAdmission ? (
                                <>
                                    <li>
                                        <span>Section</span>GA
                                    </li>
                                    <li>
                                        <span>Row</span>-
                                    </li>
                                    <li>
                                        <span>Seat</span>-
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li>
                                        <span>Section</span>{uniqueTickets[0]?.sectionNumber}
                                    </li>
                                    <li>
                                        <span>Row</span>{uniqueTickets[0]?.rowNumber}
                                    </li>
                                    <li>
                                        <span>Seat</span>{uniqueTickets?.map((ticket, index) => (
                                            <Fragment key={index}>
                                                {ticket?.seatNumber} {index < uniqueTickets?.length - 1 && ', '}
                                            </Fragment>
                                        ))}
                                    </li>
                                </>
                            )}
                        </ul>
                    )}
                </Card>
                <ListGroup as="ul" className="mt-4">
                    <ListGroup.Item as="li">
                        <div className="split-row">
                            <h1 className="normal">{invoice?.tickets?.length} Ticket/s {invoice?.details.listings?.length > 0 && (<span className='text-uppercase'>Resale</span>)} {invoice?.paymentProcessor === 'transfer' && (<span className='text-uppercase'>Transfer</span>)}</h1>

                            <span className='fw-bold'>{formatCurrency(totalAmount)}</span>
                        </div>
                        <ul>
                            <li className='list-item'>
                                {formatCurrency(ticketAmount)} x {invoice?.tickets?.length}
                            </li>
                        </ul>
                    </ListGroup.Item>
                    <ListGroup.Item as="li">
                        <div className="split-row">
                            <h1 className="normal">Fees</h1>
                            <span className='fw-bold'>{formatCurrency(totalFees)}</span>
                        </div>
                        <ul>
                            <li className='list-item'>
                                {formatCurrency(facilityFee)} (Facility Charge)
                            </li>
                            <li className='list-item'>
                                {formatCurrency(processingFee)} (Processing Charge)
                            </li>
                            <li className='list-item'>
                                {formatCurrency(tax)} (Tax)
                            </li>
                        </ul>
                    </ListGroup.Item>
                </ListGroup>
                <div className="split-row total-row">
                    <span>Total</span>
                    <span>{formatCurrency(invoice?.total)}</span>
                </div>
            </div>
        </div>
    );
}
