import { useEffect, useState } from "react";

import { GAModalsProvider } from "./GAModalsProvider";
import { GACheckoutProvider } from "./GACheckoutProvider";

import { getTicketGroups } from "../../../utilities/api";

import GAPurchaseContainer from "./GAPurchaseContainer/GAPurchaseContainer";

import "../purchaseFlow.scss";
import "./newGAPurchaseWrapper.scss";
import { GAUnlockProvider } from "./GAUnlockProvider/GAUnlockProvider";
import { useLocation, useNavigate } from "react-router-dom";

export default function NewGAPurchaseWrapper({ event, listings }) {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  // Regular state
  const accessCodeParam = searchParams.get("access_code");
  const [accessCodes, setAccessCodes] = useState(
    accessCodeParam ? accessCodeParam.split(",") : []
  );
  const [quickpicks, setQuickpicks] = useState([]);
  const [isSoldout, setIsSoldout] = useState(false);
  const [selectedFromQuickpicks, setSelectedFromQuickpicks] = useState(null); // { quantity, quickpick }
  const [isLoading, setIsLoading] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(false);

  // Hide intercom widget on this page
  // This does hide it until you refresh the page / turn it back on again
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    }
  }, []);

  const selectQuickPick = (pick, quantity) => {
    console.log("selectQuickPick: ", { ...pick, quantity });
    setSelectedFromQuickpicks({ ...pick, quantity });
  };

  const multiUpdateSearchParam = (listOfKVs) => {
    listOfKVs.forEach((kv) => {
      searchParams.set(kv[0], kv[1]);
    });
    navigate(`${location.pathname}?${searchParams}`);
  };

  useEffect(() => {
    const accessCodesString = searchParams.get("access_code");
    const accessCodes = accessCodesString ? accessCodesString.split(",") : [];

    setAccessCodes(accessCodes);
  }, [location.search]);

  useEffect(() => {
    fetchTicketGroups();
  }, [accessCodes]);

  const fetchTicketGroups = async () => {
    setIsLoading(true);
    let data = {
      event,
      quantity: 1,
      offerIds: [],
      priceRange: [0, 500], // this is not considered at all
      accessCodes: accessCodes,
      accessible: false, // false returns them still;; does it?
      sort: "bestseat",
      returnLocked: true,
    };
    let res = await getTicketGroups(data);
    // Only add listings if we're not filtering by specific offerIds
    if (res.data?.ticketGroups) {
      setQuickpicks([...res.data.ticketGroups]);
    }

    if (res.data.soldout) {
      console.log("event is soldout...");
      setIsSoldout(true);
    }

    setIsLoading(false);
  };

  return (
    <GACheckoutProvider
      event={event}
      selectedFromQuickpicks={selectedFromQuickpicks}
    >
      <GAUnlockProvider accessCodes={accessCodes} multiUpdateSearchParam={multiUpdateSearchParam}>
        <GAModalsProvider
          event={event}
          quickpicks={quickpicks}
          selectQuickPick={selectQuickPick}
          isLoading={isLoading}
          isScheduled={isScheduled}
          scheduledTime={scheduledTime}
        >
          <GAPurchaseContainer
            isLoading={isLoading}
            selectedFromQuickpicks={selectedFromQuickpicks}
            event={event}
            isScheduled={isScheduled}
            scheduledTime={scheduledTime}
            listings={listings}
            isSoldout={isSoldout}
            quickpicks={quickpicks}
            selectQuickPick={selectQuickPick}
          />
        </GAModalsProvider>
      </GAUnlockProvider>
    </GACheckoutProvider>
  );
}
