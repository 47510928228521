import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSeatmapStore from "../../../../../stores/seatmapStore";

import { getTicketGroups } from "../../../../../utilities/api";

export const TicketContext = createContext();

export const useTickets = () => {
    const context = useContext(TicketContext);
    if (!context) {
        throw new Error("Context must be used within a TicketsProvider");
    }
    return context;
};


// TODO: move all of this shit into Zustand
// Provides all state/functionality for ticket processing
export const TicketsProvider = ({
    offers,
    event,
    code,
    priceRangeMinMax,
    children,
}) => {
    // Zustand store
    const selectedFromMap = useSeatmapStore(state => state.selectedFromMap); // GA & Seated both have ticketGroup, GA has quantity, Seated has seatId

    const [totalCount, setTotalCount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [quickpicks, setQuickpicks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingTickets, setIsLoadingTickets] = useState(true);
    const [needToUnlock, setNeedToUnlock] = useState(false);

    // FILTERING
    const location = useLocation();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const quantityParam = searchParams.get("quantity");
    const sortParam = searchParams.get("sort");
    const accessCodeParam = searchParams.get("access_code");

    const initialQuantity = quantityParam ? parseInt(quantityParam, 10) : 2;
    const intialSort = sortParam === "bestseat" ? "bestseat" : "price";

    const [soldout, setSoldout] = useState(false);
    const [accessCodes, setAccessCodes] = useState(
        accessCodeParam ? accessCodeParam.split(",") : []
    );
    const [sort, setSort] = useState(intialSort);
    const [quantity, setQuantity] = useState(initialQuantity);
    const [accessible, setAccessible] = useState(false);
    const [priceRange, setPriceRange] = useState([0, 500]);
    const [originalPriceRange, setOriginalPriceRange] =
        useState(priceRangeMinMax);
    const [selectedOfferIds, setSelectedOfferIds] = useState([]);
    const [isMappingCreated, setIsMappingCreated] = useState(false);
    const [isScheduled, setIsScheduled] = useState(false);
    const [scheduledTime, setScheduledTime] = useState(false);

    // first time loading tickets - not filtering
    useEffect(() => {
        console.log("first load");
        setIsLoading(true);
    }, []);

    useEffect(() => {
        // Calculate total price
        const newTotalPrice = selectedFromMap.map((ticketGroup) => {
            if (ticketGroup.GA) {
                return ticketGroup.quantity * ticketGroup.price;
            } else {
                return ticketGroup.price;
            }
        }).reduce((sum, price) => sum + price, 0);
        const seatedCount = selectedFromMap
            .filter((ticketGroup) => ticketGroup.GA === false)
            .map((ticketGroup) => ticketGroup.seatId).length;
        const gaCount = selectedFromMap
            .filter((ticketGroup) => ticketGroup.GA === true)
            .reduce((sum, ticketGroup) => sum + ticketGroup.quantity, 0);

        const newTotalCount = seatedCount + gaCount;

        setTotalCount(newTotalCount);
        setTotalPrice(newTotalPrice);
    }, [selectedFromMap]);

    useEffect(() => {
        const isAccessible = searchParams.get("accessible") === "true";
        const quantity = parseInt(searchParams.get("quantity"), 10) || 2;
        const sort = searchParams.get("sort") === "price" ? "price" : "bestseat";
        const accessCodesString = searchParams.get("access_code");
        const accessCodes = accessCodesString ? accessCodesString.split(",") : [];

        const offerIdsFromUrl = searchParams.get("offers");
        const selectedOfferIds = offerIdsFromUrl
            ? offerIdsFromUrl.split(",").map((id) => parseInt(id, 10))
            : [];

        let priceRange;
        const priceRangeFromUrl = searchParams.get("price");

        if (priceRangeMinMax) {
            const [minAvailable, maxAvailable] = priceRangeMinMax;

            if (priceRangeFromUrl) {
                const [minFromUrl, maxFromUrl] = priceRangeFromUrl
                    .split(",")
                    .map((price) => parseInt(price, 10));

                priceRange = [
                    Math.max(minFromUrl, minAvailable),
                    Math.min(maxFromUrl, maxAvailable),
                ];
            } else {
                priceRange = [minAvailable, maxAvailable];
            }
        } else {
            // If priceRangeMinMax is not available, use URL values or default
            priceRange = priceRangeFromUrl
                ? priceRangeFromUrl.split(",").map((price) => parseInt(price, 10))
                : [0, 5000]; // Default price range if not provided
        }

        // event.id, quantity, priceRange, selectedOfferIds
        if (!event) return;
        getQuickPicksAndListings(
            event,
            quantity,
            priceRange,
            selectedOfferIds,
            accessCodes,
            isAccessible,
            sort
        );

        setQuantity(quantity);
        setSort(sort);
        setAccessCodes(accessCodes);
        setSelectedOfferIds(selectedOfferIds);
        setPriceRange(priceRange);
        setAccessible(isAccessible);
    }, [location.search]);

    const reloadQuickPicks = () => {
        setIsLoading(true);
        getQuickPicksAndListings(
            event,
            quantity,
            priceRange,
            selectedOfferIds,
            accessCodes,
            accessible,
            sort
        );
    };

    const updateSearchParam = (key, value) => {
        searchParams.set(key, value);
        navigate(`${location.pathname}?${searchParams}`);
    };

    const multiUpdateSearchParam = (listOfKVs) => {
        listOfKVs.forEach((kv) => {
            searchParams.set(kv[0], kv[1]);
        });
        navigate(`${location.pathname}?${searchParams}`);
    };

    const getQuickPicksAndListings = async (
        event,
        quantity,
        priceRange,
        offerIds,
        accessCodes,
        accessible,
        sort
    ) => {
        setIsLoadingTickets(true);
        try {
            let data = {
                event,
                quantity,
                priceRange,
                offerIds,
                accessCodes,
                accessible,
                sort,
                returnLocked: false,
            };
            let res = await getTicketGroups(data);
            console.log("quickpicks res:", res.data);

            setIsScheduled(res.data.isScheduled);
            setScheduledTime(res.data.scheduledTime);
            setIsMappingCreated(true);
            setNeedToUnlock(res.data.needToUnlock);

            // Only add listings if we're not filtering by specific offerIds
            if (offerIds.length === 0 && !accessible) {
                setQuickpicks([
                    ...res.data.ticketGroups
                ]);
            } else {
                // If searching for specific offerIds, only add ticketGroups
                setQuickpicks([...res.data.ticketGroups]);
            }
            if (res.data.soldout) {
                console.log("event is soldout...");
                setSoldout(true);
            }
        } catch (error) {
            console.error("Error fetching quickpicks:", error);
        } finally {
            setIsLoadingTickets(false);
            setIsLoading(false);
        }
    };

    return (
        <TicketContext.Provider
            value={{
                isMappingCreated,
                isLoading,
                isLoadingTickets,
                offers,
                event,
                code,
                isScheduled,
                scheduledTime,
                quickpicks,
                sort,
                quantity,
                priceRange,
                updateSearchParam,
                multiUpdateSearchParam,
                selectedOfferIds,
                totalCount,
                totalPrice,
                originalPriceRange,
                accessCodes,
                soldout,
                reloadQuickPicks,
                accessible,
                needToUnlock,
            }}
        >
            {children}
        </TicketContext.Provider>
    );
};
