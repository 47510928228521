import React, { useMemo } from "react";
import { useTooltips } from "../../../providers/TooltipProvider";
import useSeatmapStore from "../../../../../../../../../stores/seatmapStore";

let globalHoverTimeout = null;
const TOOLTIP_TIMEOUT = 500;

const Seat = React.memo(
  ({ seat }) => {
    // Zustand store
    const selectSpecificSeat = useSeatmapStore(state => state.selectSpecificSeat);
    const unselectSeat = useSeatmapStore(state => state.unselectSeat);
    const seatLookupTable = useSeatmapStore(state => state.seatLookupTable);

    const ticketGroup = useMemo(
      () => seatLookupTable[seat.seatId],
      [seatLookupTable, seat.seatId]
    );

    const { showTooltipFromSeat, closeIfSpecificTooltip } = useTooltips();

    const handleSeatClick = (event) => {
      clearTimeout(globalHoverTimeout);
      if (
        ticketGroup?.offer?.accessCode &&
        ticketGroup?.offer?.accessCode !== null &&
        !ticketGroup?.offer?.unlocked
      ) {
        let simulatedInventory = [
          {
            availableCount: 1,
            ...ticketGroup,
          },
        ];
        showTooltipFromSeat(
          event.currentTarget,
          seat.seatId,
          5,
          undefined,
          simulatedInventory
        );
      } else if (window.innerWidth <= 768) {
        showTooltipFromSeat(
          event.currentTarget,
          seat.seatId,
          2,
          ticketGroup,
          undefined
        );
      } else {
        selectSpecificSeat(seat.seatId, ticketGroup);
      }
    };

    const handleUnselectSeat = () => {
      clearTimeout(globalHoverTimeout);
      unselectSeat(seat.seatId, ticketGroup);
    }

    const handleMouseEnter = (event) => {
      if (
        window.innerWidth <= 768 ||
        (ticketGroup?.offer?.accessCode &&
          ticketGroup?.offer?.accessCode !== null &&
          !ticketGroup?.offer?.unlocked)
      ) {
        return;
      }

      let target = event.currentTarget;
      clearTimeout(globalHoverTimeout);
      globalHoverTimeout = setTimeout(() => {
        showTooltipFromSeat(
          target,
          seat.seatId,
          1,
          ticketGroup,
          undefined
        );
      }, TOOLTIP_TIMEOUT);
    };

    const handleMouseLeave = () => {
      if (window.innerWidth <= 768) {
        return;
      }

      clearTimeout(globalHoverTimeout);
      closeIfSpecificTooltip(1); // close if showing SeatTooltip
    };

    const seatColor = useMemo(() => {
      if (seat.selected) return "#45B26B";
      if (!ticketGroup) return "#E6E8EC";
      if (ticketGroup?.accessible) return "#FFA500";
      if (
        ticketGroup?.offer &&
        ticketGroup?.offer.accessCode !== null &&
        !ticketGroup?.offer?.unlocked
      )
        return "#353945";
      return ticketGroup.resale
        ? "#E06C35"
        : ticketGroup?.offer?.inventoryType === "exclusive"
        ? "#9757D7"
        : "#3E8BF7";
    }, [seat.selected, ticketGroup]);

    const seatProps = {
      // ref: seatRef,
      id: seat.seatId,
      className: `seat ${ticketGroup && "--hover"}`,
      x: seat.cx,
      y: seat.cy,
      rx: 33,
      ry: 33,
      width: seat.w,
      height: seat.h,
      fill: seatColor,
      style: { cursor: ticketGroup ? "pointer" : "default" },
      ...(ticketGroup && {
        onClick: seat.selected
          ? handleUnselectSeat
          : handleSeatClick,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      }),
    };

    const getIconId = () => {
      if (seat.selected) return "icon-selected";
      if (ticketGroup && ticketGroup.resale) return "icon-resale";
      if (ticketGroup && ticketGroup?.accessible) return "icon-accessible";
      if (ticketGroup && ticketGroup?.offer?.unlocked) return "icon-unlocked";
      if (ticketGroup && ticketGroup?.offer?.accessCode) return "icon-locked";
      if (ticketGroup && ticketGroup?.offer?.inventoryType === "exclusive") {
        return "icon-vip";
      }
      return null;
    };

    const iconId = getIconId();
    return (
      <g>
        <rect {...seatProps} />
        {iconId && (
          <use
            href={`#${iconId}`}
            x={seat.cx + seat.w * 0.2}
            y={seat.cy + seat.h * 0.2}
            width={seat.w * 0.6}
            height={seat.h * 0.6}
            pointerEvents={"none"}
            fill='white'
          />
        )}
      </g>
    );
  }
);

export default Seat;
