import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import SeatmapModal from "../../PurchaseFlow/NewSeatedPurchaseWrapper/providers/SeatmapProvider/SeatmapModal/SeatmapModal";
import { TicketErrorModal } from "../../PurchaseFlow/NewSeatedPurchaseWrapper/providers/CheckoutProvider/TicketErrorModal";

import { PackageSeatmapWrapper } from "./PackageSeatmapWrapper";
import useSeatmapStore from "../../../stores/seatmapStore";

// Create the context
const PackageSeatmapContext = createContext(undefined);

export const usePackageSeatmap = () => {
    const context = useContext(PackageSeatmapContext);
    if (!context) {
        throw new Error("Context must be used within a PackageSeatmapProvider");
    }
    return context;
};

// Provide the functionality for displaying a seatmap inside a modal
export const PackageSeatmapProvider = ({ eventPackage, children }) => {
    // Zustand
    const setData = useSeatmapStore(state => state.setData);
    const setBackground = useSeatmapStore(state => state.setBackground);
    const selectedFromMap = useSeatmapStore(state => state.selectedFromMap);
    const resetMapSelection = useSeatmapStore(state => state.resetMapSelection);

    const dialogRef = useRef(null);
    const errorDialogRef = useRef(null);
    const [seatedError, setSeatedError] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        setData(eventPackage?.seatmap?.mapping);
        setBackground(eventPackage?.seatmap?.background);
    }, [eventPackage]);

    const showSeatmap = () => {
        setDialogOpen(true);
        dialogRef.current.showModal();
    };

    const hideSeatmap = () => {
        confirmCloseMap();
    };

    const unselectAllSeats = (seatIds) => {
        setData((prev) => {
            // Safety check for undefined prev
            if (!prev) return {};

            const { seats } = prev;

            const updatedSeats = {
                ...seats,
                ...Object.fromEntries(
                    seatIds.map((seatId) => [
                        seatId,
                        { ...seats[seatId], selected: false },
                    ])
                ),
            };

            return {
                ...prev,
                seats: updatedSeats,
            };
        });
    };

    const confirmCloseMap = () => {
        setDialogOpen(false);
        dialogRef.current.close();

        // Wipe entire selection
        resetMapSelection();
    };

    const modalClose = () => {
        setSeatedError(null);
        errorDialogRef.current.close();
        // Probably just want to refresh here if tickets not available
    };

    useEffect(() => {
        if (seatedError) {
            errorDialogRef.current.showModal();
        }
    }, [seatedError])

    return (
        <PackageSeatmapContext.Provider
            value={{ showSeatmap, eventPackage, setSeatedError }}
        >
            <SeatmapModal
                dialogRef={dialogRef}
                hideSeatmap={hideSeatmap}
                title={"Select your seats"}
                dialogOpen={dialogOpen}
                SeatmapWrapper={PackageSeatmapWrapper}
            />
            {/* Change modal */}
            <TicketErrorModal
                dialogRef={errorDialogRef}
                seatedError={seatedError}
                handleClose={modalClose}
            />
            {children}
        </PackageSeatmapContext.Provider>
    );
};
