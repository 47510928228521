import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
    lazy,
} from "react";
import { useTickets } from "../TicketsProvider/TicketsProvider";

import SeatmapModal from "./SeatmapModal/SeatmapModal";
import ConfirmExitModal from "./ConfirmExitModal/ConfirmExitModal";
import useSeatmapStore from "../../../../../stores/seatmapStore";

const SeatmapWrapper = lazy(() => import("./SeatmapWrapper/SeatmapWrapper"));

// Create the context
const SeatmapContext = createContext(undefined);

export const useSeatmap = () => {
    const context = useContext(SeatmapContext);
    if (!context) {
        throw new Error("Context must be used within a SeatmapProvider");
    }
    return context;
};

// Provide the functionality for displaying a seatmap inside a modal
export const SeatmapProvider = ({ children }) => {
    // Zustand
    const selectedFromMap = useSeatmapStore(state => state.selectedFromMap);
    const resetMapSelection = useSeatmapStore(state => state.resetMapSelection);

    const { event } = useTickets();
    const dialogRef = useRef(null);
    const secondDialogRef = useRef(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    // Zustand store
    const setData = useSeatmapStore(state => state.setData);
    const setBackground = useSeatmapStore(state => state.setBackground);

    useEffect(() => {
        setData(event?.seatmap?.mapping);
        setBackground(event?.seatmap?.background);
    }, [event]);

    const showSeatmap = () => {
        setDialogOpen(true);
        dialogRef.current.showModal();
    };

    const hideSeatmap = () => {
        // Ask for confirmation if we have something selected
        if (selectedFromMap?.length > 0) {
            secondDialogRef.current.showModal();
        } else {
            confirmCloseMap();
        }
    };

    const confirmCloseMap = () => {
        setDialogOpen(false);
        dialogRef.current.close();
        secondDialogRef.current.close();

        // Wipe selection
        resetMapSelection();
    };

    const cancelCloseMap = () => {
        secondDialogRef.current.close();
    };

    return (
        <SeatmapContext.Provider
            value={{ showSeatmap, hideSeatmap }}
        >
            <SeatmapModal
                dialogRef={dialogRef}
                hideSeatmap={hideSeatmap}
                title={"Select your seats"}
                dialogOpen={dialogOpen}
                SeatmapWrapper={SeatmapWrapper} // make it lazy
            />
            <ConfirmExitModal
                dialogRef={secondDialogRef}
                confirm={confirmCloseMap}
                cancel={cancelCloseMap}
            />
            {children}
        </SeatmapContext.Provider>
    );
};
