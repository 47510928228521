import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import LoadingContext from '../../context/Loading/Loading';

import { getVenue, getVenuePackages } from '../../utilities/api';

import { PageLoadingContainer, VenueInformation } from '../../components';

export default function VenuePage() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    let { slug } = useParams();

    const [
        venue,
        setVenue
    ] = useState();

    const [packages, setPackages] = useState()

    useEffect(() => {
        showLoading()
        getVenue(slug)
            .then((res) => {
                const venue = res.data?.find((venue) => venue.slug === slug)
                setVenue(venue)
                getVenuePackages(venue?.slug)
                    .then((res) => {
                        console.log(res.data);
                        setPackages(res?.data)
                    })
                    .catch((err) => console.error(err))
            }).catch((err) => {
                console.error(err)
            })
            .finally(() => hideLoading())
    }, [slug]);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="keywords" content={`Calendar | Buy tickets and find event information for upcoming events by ${venue?.name}`} />
                <title>{`${venue?.name} | Event Calendar`}</title>
                {/* Facebook Meta Tags */}
                <meta property="og:url" content={`${window.location.href}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`${venue?.name} | Event Calendar`} />
                <meta property="og:description" content={`Calendar | Buy tickets and find event information for upcoming events by ${venue?.name}`} />
                <meta property="og:image" content={venue?.image[0]?.url} />
                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content={`${window.location.host}`} />
                <meta property="twitter:url" content={`${window.location.href}`} />
                <meta name="twitter:title" content={`${venue?.name} | Event Calendar`} />
                <meta name="twitter:description" content={`Calendar | Buy tickets and find event information for upcoming events by ${venue?.name}`} />
                <meta name="twitter:image" content={venue?.image[0]?.url} />
            </Helmet>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section>
                    <VenueInformation venue={venue} packages={packages} />
                </section>
            )}
        </Fragment>
    );
}
