import { TicketsProvider } from "../NewSeatedPurchaseWrapper/providers/TicketsProvider/TicketsProvider";
import { ModalsProvider } from "./providers/ModalsProvider";
import { SpecialDiscountProvider } from "./providers/SpecialDiscountProvider";

import { NewCheckoutContainer } from "./NewCheckoutContainer";

export default function NewCheckoutWrapper() {
    return (
        <SpecialDiscountProvider>
            <ModalsProvider>
                <NewCheckoutContainer />
            </ModalsProvider>
        </SpecialDiscountProvider>
    );
}
