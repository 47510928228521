import { useEffect, useState } from "react";

import { formatCurrency } from "../../../../../../utilities/helpers";

import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

import DetailsButton from "../../../DetailsButton/DetailsButton";
import { AddSubtractQuantity } from "../../../AddSubtractQuantity";
import { Spinner } from "../../../../../LoadingContainer/Spinner";

import "./GATickets.scss";
import { QuickpickBadge } from "../../../../NewSeatedPurchaseWrapper/NewSeatedPurchaseContainer/TicketSelectionPanel/ListingsContainer/TicketGroup/TicketGroup";
import { useGAUnlock } from "../../../../NewGAPurchaseWrapper/GAUnlockProvider/GAUnlockProvider";

export default function GATickets({
  quickpicks,
  selectQuickPick,
  checkingAvailability,
}) {
  const { openUnlockModal } = useGAUnlock();
  const [openIndex, setOpenIndex] = useState(0);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);

  return (
    <ul className='vjx-ga-ul'>
      {quickpicks.map((quickpick, index) => {
        // Check if quickpick is not an empty array and has properties
        if (!quickpick.GA) {
          return null; // Don't render anything for empty arrays
        }

        if (quickpick.offer.accessCode && !quickpick?.offer?.unlocked) {
          return (
            <LockedTicket
              key={index}
              index={index}
              open={openIndex === index}
              quickpick={quickpick}
              openUnlockModal={openUnlockModal}
            />
          );
        }

        return (
          <GATicket
            key={index}
            index={index}
            open={openIndex === index}
            setOpen={() => setOpenIndex(index)}
            detailsOpen={detailsOpen}
            setDetailsOpen={setDetailsOpen}
            quantity={quantity}
            setQuantity={setQuantity}
            quickpick={quickpick}
            selectQuickPick={selectQuickPick}
            checkingAvailability={checkingAvailability}
          />
        );
      })}
    </ul>
  );
}

function LockedTicket({ index, open, quickpick, openUnlockModal }) {
  const offer = quickpick.offer;
  return (
    <li
      aria-controls={`ticket-collapse-text-${index}`}
      aria-expanded={open}
      className={`vjx-ga-ticket ${open ? "--active" : ""}`}
    >
      <Stack direction="horizontal" className="justify-content-between">
        <div className='vjx-locked-left-col'>
          <span className='vjx-ticket-type'>GA (Standing Room)</span>
          <QuickpickBadge
            isListing={false} // Val: change this to true if pick is listing
            listing={undefined}
            inventoryType={offer?.inventoryType}
            offerName={offer?.name}
          />
        </div>
        <Button
          variant='primary'
          size='lg'
          className='d-flex justify-content-center m-0'
          onClick={() => openUnlockModal(offer)}
        >
          Unlock
        </Button>
      </Stack>
    </li>
  );
}

function GATicket({
  index,
  open,
  setOpen,
  detailsOpen,
  setDetailsOpen,
  quantity,
  setQuantity,
  quickpick,
  selectQuickPick,
  checkingAvailability,
}) {
  const offer = quickpick.offer;

  useEffect(() => {
    if (!open) {
      setDetailsOpen(false);
    }
  }, [open, setDetailsOpen]);

  const handleDetailsButtonClick = (e) => {
    e.stopPropagation(); // Prevent the click from bubbling up to the parent button
    setDetailsOpen(!detailsOpen);
  };

  const getQuantityStep = (offer, currentQuantity) => {
    // If there's a limit, we either go to the limit or 0
    if (offer.limit) {
      return offer.limit; // Either jump to limit or back to 0
    }

    if (offer.minQuantity && currentQuantity < offer.minQuantity) {
      return offer.minQuantity;
    }

    // If there's a multipleOf constraint
    if (offer.multipleOf) {
      return offer.multipleOf;
    }

    // Default step size is 1
    return 1;
  };

  // Utility function to validate the new quantity against constraints
  const validateNewQuantity = (newQuantity, offer) => {
    // Don't go below 0
    if (newQuantity < 0) {
      return 0;
    }

    // Special handling for limit: only allow 0 or exactly the limit value
    if (offer.limit) {
      // If trying to set any value other than 0 or the limit, return null
      if (newQuantity !== 0 && newQuantity !== offer.limit) {
        return null;
      }
      return newQuantity;
    }

    // Check max quantity constraint
    if (offer.maxQuantity && newQuantity > offer.maxQuantity) {
      return null; // Indicates invalid quantity
    }

    // Check minimum quantity constraint
    if (
      offer.minQuantity &&
      newQuantity < offer.minQuantity &&
      newQuantity !== 0
    ) {
      return 0;
    }

    return newQuantity;
  };

  const increment = (offer) => {
    const step = getQuantityStep(offer, quantity);
    const newQuantity = validateNewQuantity(quantity + step, offer);

    if (newQuantity !== null) {
        setQuantity(newQuantity)
    }
  };

  const decrement = (offer) => {
    const step = getQuantityStep(offer, quantity);
    const newQuantity = validateNewQuantity(quantity - step, offer);

    if (newQuantity !== null) {
        setQuantity(newQuantity)
    }
  };

  console.log("gaTicket: ", quickpick);
  return (
    <li
      onClick={setOpen}
      aria-controls={`ticket-collapse-text-${index}`}
      aria-expanded={open}
      className={`vjx-ga-ticket ${open ? "--active" : ""}`}
    >
      <Stack>
        <div className='vjx-ga-txt-col'>
          <span className='vjx-ticket-type'>GA (Standing Room)</span>
          <span className='vjx-ticket-price'>
            {formatCurrency(quickpick?.price)}
          </span>
        </div>
        <div className='vjx-ga-txt-col'>
          <QuickpickBadge
            isListing={false} // Val: change this to true if pick is listing
            listing={undefined}
            inventoryType={offer?.inventoryType}
            offerName={offer?.name}
          />
          <span className='vjx-ticket-taxes'>Incl. Taxes & Fees</span>
        </div>
      </Stack>
      <Collapse in={open}>
        <div id={`ticket-collapse-text-${index}`}>
          <div className='vjx-collapse-one'>
            {offer?.description ? (
              <DetailsButton
                text={"Ticket details"}
                onClick={handleDetailsButtonClick}
                aria-controls={`ticket-details-text-${index}`}
                aria-expanded={detailsOpen}
              />
            ) : (
              <span className='caption caption-bold text-muted'>
                Ticket limit: {offer?.limit || 50}
              </span>
            )}
            <div className='vjx-quantity-button'>
              <AddSubtractQuantity
                whiteBG={true}
                quantity={quantity}
                increment={increment}
                decrement={decrement}
              />
              <Button
                onClick={(e) => selectQuickPick(quickpick, quantity)}
                disabled={checkingAvailability}
                variant='primary'
                size='lg'
                style={{ width: "128px", height: "48px" }}
                className='d-flex justify-content-center m-0'
              >
                {checkingAvailability ? <Spinner /> : "Checkout"}
              </Button>
            </div>
          </div>
        </div>
      </Collapse>
      <Collapse in={detailsOpen}>
        <div id={`ticket-details-text-${index}`}>
          <div className='vjx-collapse-two'>
            <span>{offer?.description}</span>
          </div>
        </div>
      </Collapse>
    </li>
  );
}
