import { useRef } from "react";
import { useActions } from "../../providers/ActionsProvider";
import { useTooltips } from "../../providers/TooltipProvider";
import useSeatmapStore from "../../../../../../../../stores/seatmapStore";

export default function Sections({
  hidePath,
  checkViewBoxIntersection  
}) {
  // Zustand
  const data = useSeatmapStore(state => state.data);
  const scaleThreshold = useSeatmapStore(state => state.scaleThreshold);
  const setScaleThreshold = useSeatmapStore(state => state.setScaleThreshold);
  const sectionLookupTable = useSeatmapStore(state => state.sectionLookupTable);
  const setScale = useSeatmapStore(state => state.setScale);
  const arePathsHidden = useSeatmapStore(state => state.arePathsHidden);
  const setArePathsHidden = useSeatmapStore(state => state.setArePathsHidden);


  const TOOLTIP_TIMEOUT = 500;
  // Perhaps move, perhaps keep
  const eventMousePos = useRef({ x: 0, y: 0 });
  // Perhaps move, perhaps keep

  const { zoomRef } = useActions();
  const {
    initialTimestamp,
    timer,
    clearPriceTipTimeout,
    showTooltipFromCoordinates,
    showTooltipFromEvent,
    closeIfSpecificTooltip,
  } = useTooltips();

  // In ga-section we are actually interested in viewing the offer/offers
  const clickGASection = (event, sectionId) => {
    clearPriceTipTimeout();

    const section = sectionLookupTable[sectionId]
    if (section) {
      showTooltipFromEvent(event, 4, section);
    }
  };

  const zoomToSection = (event, sectionId) => {
    const ZOOM_ANIMATION_TIME = 450;
    zoomRef.current.zoomToElement(sectionId, undefined, ZOOM_ANIMATION_TIME);
    // Delay until zoom is finished, then hidePath
    setTimeout(() => {
      hidePath.current = true;
      setArePathsHidden(true);

      // Default scaleThreshold is 4, if we zoomToElement and scale is smaller than treshold
      // change threshold -> so following zoomIn etc wont hide sections
      const scaleAfterZoomTo = zoomRef.current.state.scale;
      if (scaleAfterZoomTo < scaleThreshold) {
        setScaleThreshold(scaleAfterZoomTo)
      };
      
      setScale(scaleAfterZoomTo);
      // Find intersecting sections, draw seats
      // Since zoom might not scale enough to automatically do it
      // This by itself is enough to draw other intersecting sections when panning
      checkViewBoxIntersection();
    }, ZOOM_ANIMATION_TIME);
  };

  // Update eventMousePos on mousemove
  const pathMove = (event) => {
    if (
      !(
        initialTimestamp.current &&
        event.timeStamp - initialTimestamp.current > TOOLTIP_TIMEOUT
      )
    ) {
      eventMousePos.current = { x: event.pageX, y: event.pageY };
    }
  };

  // On enter start countdown to show tooltip
  const pathEnter = (event, sectionId) => {
    if (window.innerWidth <= 768) {
      return;
    }

    if (!initialTimestamp.current) {
      initialTimestamp.current = event.timeStamp;
      timer.current = setTimeout(
        () => showPriceTooltipFromState(sectionId),
        TOOLTIP_TIMEOUT
      );
    }
  };

  const showPriceTooltipFromState = (sectionId) => {
    const section = sectionLookupTable[sectionId]

    if (!section) {
      console.error(`Section with id ${sectionId} not found`);
      return;
    }

    if (!section) {
      console.error(`No inventories found for section ${sectionId}`);
      return;
    }

    // Todo find lowest price in inventory ( for from: 10$ )
    const lowestPriceInventory = { price: 10 }

    showTooltipFromCoordinates(
      eventMousePos.current.x,
      eventMousePos.current.y,
      3,
      lowestPriceInventory.price
    );
  };

  const isSectionSoldout = (sectionId) => {
    const section = sectionLookupTable[sectionId]
    return !section;
  };

  return (
    <g className='polygons'>
      {data?.sections &&
        Object.values(data?.sections).map((section, index) => {
          return (
            <Section
              key={index}
              section={section}
              sectionSoldout={isSectionSoldout(section.sectionId)}
              arePathsHidden={arePathsHidden}
              onZoom={zoomToSection}
              onClick={clickGASection}
              onMouseMove={pathMove}
              onMouseEnter={pathEnter}
              onMouseLeave={() => closeIfSpecificTooltip(3)} // if priceTip is open, close
            />
          );
        })}
    </g>
  );
}

const Section = ({
  section,
  sectionSoldout,
  arePathsHidden,
  onZoom,
  onClick,
  onMouseMove,
  onMouseEnter,
  onMouseLeave,
}) => {
  const UNAVAILABLE_COLOR = "#E6E8EC";
  const UNAVAILABLE_IDENTIFIER_COLOR = "#B1B5C4";
  const hidePathClass = (section.zoomable && arePathsHidden && !sectionSoldout) ? "pathInvisible" : "";

  // Color change based on availability
  const sectionFill = sectionSoldout ? UNAVAILABLE_COLOR : section.fill;
  const sectionStroke = sectionSoldout ? UNAVAILABLE_COLOR : section.stroke;
  const identifierFill = sectionSoldout
    ? UNAVAILABLE_IDENTIFIER_COLOR
    : section.identifier?.fill;

  const sectionProps = {
    fill: sectionFill,
    stroke: sectionStroke,
    strokeWidth: section.strokeWidth,
    d: section.path,
    id: section.sectionId,
    className: `path ${hidePathClass} ${!sectionSoldout && "path-onsale"}`,
    style: { cursor: sectionSoldout ? "default" : "pointer" },
    // First check if we add user actions to section, based on availability
    // Then add the user actions specific to GA or SEATED section
    ...(!sectionSoldout && {
      onClick: (event) =>
        section.zoomable
          ? onZoom(event, section.sectionId)
          : onClick(event, section.sectionId),
      onMouseMove: section.zoomable ? null : onMouseMove,
      onMouseOver: section.zoomable
        ? null
        : (event) => onMouseEnter(event, section.sectionId),
      onMouseOut: onMouseLeave,
    }),
  };

  return (
    <g data-section-id={section.sectionId}>
      <path {...sectionProps} />
      <path
        style={{ pointerEvents: "none" }}
        className={hidePathClass}
        fill={identifierFill}
        d={section.identifier?.path}
        opacity={section.identifier?.opacity}
      />
    </g>
  );
};
