import React from 'react';
import { Link } from 'react-router-dom';

import { formatDateTime, timezones, getTimezoneDate, getSinglarOrPluralWord, sort } from '../../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';

import placeholder from '../../../../assets/placeholder.png'

export default function Package({ orderId, order }) {

    const timezone = timezones(order?.venue?.timezone)

    const sortedEvents = sort(order?.events)

    return (
        <Stack gap={3} as="ul">
            <Link to={`/my-packages/${order?.uuid}/package-details/${orderId}`}>
                <Card as="li" className="card--light card-xs">
                    <Card.Body className='d-flex-column flex-md-row gap-3'>
                        <div className="card-img-container card-img-container-sm">
                            <Card.Img
                                src={order?.image?.url || placeholder}
                                alt={`Cover art for ${order?.name} package`}
                                width="78"
                                height="78"
                            />
                        </div>
                        <Stack gap={2}>
                            <Card.Title as="h5" className="card-title-sm m-0">{order?.name}</Card.Title>
                            <Card.Subtitle as="h6" className="text-secondary card-subtitle-lg">{order?.organization?.name}</Card.Subtitle>
                            <Card.Text className="small">From <span>{formatDateTime(getTimezoneDate(sortedEvents[0]?.start, timezone), 'seoDate')}</span>,  {" "}<span>{formatDateTime(getTimezoneDate(sortedEvents[0]?.start, timezone), 'timeOnly')}</span></Card.Text>
                            <span className='num-packages'>{order?.events?.length} {getSinglarOrPluralWord(order?.events?.length, 'event')}</span>
                        </Stack>
                    </Card.Body>
                </Card>
            </Link>
        </Stack>
    );
}
