import { create } from "zustand";

// For resetting state
const resetState = {
    scale: 1,
    scaleThreshold: 4,
    arePathsHidden: false,
    selectedFromMap: [],
};

const useSeatmapStore = create((set, get) => ({
    // State
    data: null,
    background: null,
    scale: 1,
    scaleThreshold: 4, // when sections become visible
    arePathsHidden: false,
    eventTicketLimit: null,
    seatedError: null,
    // lookups into ticketGroups
    seatLookupTable: {},
    sectionLookupTable: {},
    selectedFromMap: [],
    bucket: `https://blocktickets.nyc3.cdn.digitaloceanspaces.com`,

    // Setters
    setData: (data) => set({ data: data }),
    setBackground: (background) => set({ background }),
    setScale: (scale) => set({ scale }),
    setScaleThreshold: (scaleThreshold) => set({ scaleThreshold }),
    setArePathsHidden: (hidden) => set({ arePathsHidden: hidden }),
    setSeatLookupTable: (seatLookupTable) => set({ seatLookupTable }),
    setSectionLookupTable: (sectionLookupTable) => set({ sectionLookupTable }),
    setSelectedFromMap: (selectedFromMap) => set({ selectedFromMap }),
    setEventTicketLimit: (eventTicketLimit) => set({ eventTicketLimit }),
    setSeatedError: (seatedError) => set({ seatedError }),

    // Helper functions
    _addToSeats: (ticketGroup) => {
        set((state) => ({
            selectedFromMap: [...state.selectedFromMap, ticketGroup],
        }));
    },

    _withingEventTicketLimit: (additionalTickets) => {
        const { selectedFromMap, eventTicketLimit } = get();
        if (!eventTicketLimit) return true;

        const currentGATickets = selectedFromMap
            .filter((ticketGroup) => ticketGroup.GA === true)
            .reduce((sum, ticketGroup) => sum + ticketGroup.quantity, 0);
        const currentSpecificSeats = selectedFromMap
            .filter((ticketGroup) => ticketGroup.GA === false)
            .map((ticketGroup) => ticketGroup.seatId).length;
        const totalCurrentTickets = currentGATickets + currentSpecificSeats;

        // Checks adding x tickets wont go above eventTicketLimit...
        return totalCurrentTickets + additionalTickets <= eventTicketLimit;
    },

    // 'Public' functions
    resetMapState: () => {
        set(resetState);
    },

    selectGASeats: (selectedGroups) => {
        console.log("selectGASeats: ", selectedGroups)
        const totalNewTickets = selectedGroups.reduce(
            (sum, { quantity }) => sum + quantity,
            0
        );

        if (!get()._withingEventTicketLimit(totalNewTickets)) {
            // Error out etc
            const { eventTicketLimit } = get();
            set((state) => ({
                seatedError: {
                    title: "Max ticket limit reached",
                    message: `Adding these tickets would exceed the ticket limit of ${eventTicketLimit}. Please change your selection.`,
                    buttonText: "Close",
                },
            }));
            return;
        }

        selectedGroups.forEach((group) => get()._addToSeats(group));
    },

    selectSpecificSeat: (id, ticketGroup) => {
        console.log("selectSpecific TG: ", ticketGroup)
        if (!get()._withingEventTicketLimit(1)) {
            const { eventTicketLimit } = get();
            set((state) => ({
                seatedError: {
                    title: "Max ticket limit reached",
                    message: `Adding these tickets would exceed the ticket limit of ${eventTicketLimit}. Please change your selection.`,
                    buttonText: "Close",
                },
            }));
            return;
        }

        set((state) => ({
            data: {
                ...state.data,
                seats: {
                    ...state.data.seats,
                    [id]: { ...state.data.seats[id], selected: true },
                },
            },
        }));

        get()._addToSeats({
            seatId: id,
            seatNumber: get().data.seats[id].seatNumber,
            ...ticketGroup,
        });
    },

    unselectSeat: (seatId, ticketGroup) => {
        if (ticketGroup.GA) {
            set((state) => ({
                selectedFromMap: state.selectedFromMap
                    .map((group) => {
                        if (
                            group.sectionId === ticketGroup.sectionId &&
                            group.id === ticketGroup.id
                        ) {
                            return {
                                ...group,
                                quantity: group.quantity - 1,
                            };
                        }
                        return group;
                    })
                    .filter((group) => !group.GA || (group.GA && group.quantity > 0)),
            }));
        } else {
            set((state) => ({
                selectedFromMap: state.selectedFromMap.filter(
                    (group) => group.seatId !== seatId
                ),
                data: {
                    ...state.data,
                    seats: {
                        ...state.data.seats,
                        [seatId]: { ...state.data.seats[seatId], selected: false },
                    },
                },
            }));
        }
    },

    resetMapSelection: () => {
        set((state) => {
            const allSelectedSeatIds = state.selectedFromMap
                .filter((ticketGroup) => ticketGroup?.seatId !== undefined)
                .map((ticketGroup) => ticketGroup.seatId);

            // Safety check for undefined state.data
            if (!state.data) return { selectedFromMap: [] };

            const { seats } = state.data;

            const updatedSeats = {
                ...seats,
                ...Object.fromEntries(
                    allSelectedSeatIds.map((seatId) => [
                        seatId,
                        { ...seats[seatId], selected: false },
                    ])
                ),
            };

            return {
                selectedFromMap: [],
                data: {
                    ...state.data,
                    seats: updatedSeats,
                },
            };
        });
    },

    getTicketImage: (venue, sectionId, type = "highlights") => {
        const { bucket } = get();
        let imgURL = `${bucket}/venues/${venue}/${type}/${sectionId}.png`
        return imgURL; // sectionId
    },
}));

export default useSeatmapStore;
