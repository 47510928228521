import React from "react";

import { PackageSeatmapProvider } from "./PackageSeatmapProvider";
import { TicketsProvider } from "../PurchaseFlow/NewSeatedPurchaseWrapper/providers/TicketsProvider/TicketsProvider";

import { PackageDetails } from "./PackageDetails";

import "./viewPackageWrapper.scss";

export default function ViewPackageWrapper({ eventPackage }) {

    console.log("eventPackage: ", eventPackage)
    return (
        <TicketsProvider event={eventPackage?.events[0]}>
            <PackageSeatmapProvider eventPackage={eventPackage}>
                <PackageDetails eventPackage={eventPackage} />
            </PackageSeatmapProvider>
        </TicketsProvider>
    );
}
