import React, {
  Suspense,
  createContext,
  useContext,
  useRef,
  useState,
  lazy,
} from "react";
import { useNavigate } from "react-router-dom";
import { CenterBottomModal } from "../../components/CenterBottomModal";

const LazyGetTickets = lazy(() =>
  import("../../components/GetStatus/GetTickets/GetTickets")
);

// Create the context
export const GAModalsContext = createContext(undefined);

export const useGAModals = () => {
  const context = useContext(GAModalsContext);
  if (!context) {
    throw new Error("Context must be used within a GAModalsProvider");
  }
  return context;
};

// Create the provider component
export const GAModalsProvider = ({
  event,
  isLoading,
  isScheduled,
  scheduledTime,
  quickpicks,
  selectQuickPick,
  children,
}) => {
  const dialogRef = useRef(null);
  const [modalType, setModalType] = useState(null);

  const openCBModal = (type) => {
    setModalType(type);
    dialogRef.current.showModal();
  };

  const closeCBModal = () => {
    setModalType(null);
    dialogRef.current.close();
  };

  const modalComponents = {
    "Select Ticket": () => (
      <LazyGetTickets
        event={event}
        isLoading={isLoading}
        isScheduled={isScheduled}
        scheduledTime={scheduledTime}
        quickpicks={quickpicks}
        selectQuickPick={selectQuickPick}
        showTitle={false}
        asCard={false}
      />
    ),
  };

  // Get the component based on the current modal type
  const ModalContent = modalType ? modalComponents[modalType] : null;
  console.log(ModalContent);

  return (
    <GAModalsContext.Provider
      value={{
        openCBModal,
      }}
    >
      <CenterBottomModal
        dialogRef={dialogRef}
        handleClose={closeCBModal}
        title={modalType}
        blurred={true}
      >
        <Suspense fallback={null}>{ModalContent && <ModalContent />}</Suspense>
      </CenterBottomModal>
      {children}
    </GAModalsContext.Provider>
  );
};
