import React, {
  Suspense,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  lazy,
} from "react";
import { CenterBottomModal } from "../../components/CenterBottomModal";

const LazyEventInformation = lazy(() =>
  import("../../components/EventInformation/EventInformation")
);

const LazyLimitedAvailability = lazy(() =>
  import("../components/LimitedAvailability")
);

const LazyReminderModal = lazy(() =>
  import("../components/PaymentMethod/Checkout/CheckoutModal/ReminderModal/ReminderModal")
);

const LazyTicketInformation = lazy(() =>
    import("../../components/TicketInformation/TicketInformation")
);

const LazyTimeoutModal = lazy(() =>
  import("../components/PaymentMethod/Checkout/CheckoutModal/TimeoutModal/TimeoutModal")
);

const LazyLeaveModal = lazy(() =>
  import(
    "../components/PaymentMethod/Checkout/CheckoutModal/LeaveModal/LeaveModal"
  )
);

const LazyCardDeclineModal = lazy(() =>
  import("../components/PaymentMethod/Checkout/CheckoutModal/CardDeclineModal")
);

// Create the context
const ModalsContext = createContext(undefined);

export const useModals = () => {
  const context = useContext(ModalsContext);
  if (!context) {
    throw new Error("Context must be used within a ModalsProvider");
  }
  return context;
};

// Provide certain type of modals used in the page
export const ModalsProvider = ({ children }) => {
  const dialogRef = useRef(null);
  const [modalType, setModalType] = useState(null);
  const [CDBBlurred, setCDBBlurred] = useState(false);
  const [modalsData, setModalsData] = useState(null);
  const [isPackage, setIsPackage] = useState(false);
  // Managing time
  const timeoutRef = useRef(null);
  const [checkoutStartTime, setCheckoutStartTime] = useState(null);

  // THIS IS NOT FUNCTIONAL; NEEDS TIME-LEFT FROM SERVER
  useEffect(() => {
    if (checkoutStartTime) {
      const startTime = new Date(checkoutStartTime).getTime();
      const timeoutDuration = 10 * 60 * 10000; // 100 minutes
      const currentTime = new Date().getTime();
      const timeLeft = Math.max(0, (startTime + timeoutDuration) - currentTime);

      if (timeLeft > 0) {
        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        // Set new timeout
        timeoutRef.current = setTimeout(() => {
          openModal('timeout');
        }, timeLeft);
      } else {
        // If already expired, show timeout modal immediately
        openModal('reminder');
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [checkoutStartTime]);

  // Modal has different bg color + mobile padding
  // In these 2 cases
  useEffect(() => {
    const shouldBlur = ["Information"].includes(modalType);
    setCDBBlurred(shouldBlur);
  }, [modalType]);

  const openModal = (type, data, isPackage) => {
    console.log("OPEN MODAL", type, data, isPackage);
    setModalType(type);
    setModalsData(data); // This can be either event or ticketInformation
    setIsPackage(isPackage);
    dialogRef.current.showModal();
  };

  const closeModal = () => {
    setModalType(null);
    setModalsData(null);
    setIsPackage(false);
    dialogRef.current.close();
  };

  const startCheckoutTimer = (timestamp) => {
    setCheckoutStartTime(timestamp);
  };

  const modalComponents = {
    "Information": () => (
      <LazyEventInformation obj={modalsData} isPackage={isPackage} />
    ),
    "Ticket Information": () => <LazyTicketInformation cart={modalsData} />,
    "Limited Availability": () => (
      <LazyLimitedAvailability errorShow={modalsData} />
    ),
    "Are you sure?": () => (
      <LazyLeaveModal event={modalsData} handleClose={closeModal} />
    ),
    "reminder": () =>(<LazyReminderModal />),
    "timeout": () => <LazyTimeoutModal />,
    "declined": () => <LazyCardDeclineModal />,
  };

  // Get the component based on the current modal type
  const ModalContent = modalType ? modalComponents[modalType] : null;

  return (
    <ModalsContext.Provider
      value={{
        openModal,
        startCheckoutTimer
      }}
    >
      <CenterBottomModal
        dialogRef={dialogRef}
        handleClose={closeModal}
        title={
          modalType === "Information"
            ? `${isPackage ? "Package" : "Event"} ${modalType}`
            : modalType
        }
        blurred={CDBBlurred}
      >
        <Suspense fallback={null}>{ModalContent && <ModalContent />}</Suspense>
      </CenterBottomModal>
      {children}
    </ModalsContext.Provider>
  );
};
