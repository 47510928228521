import { useEffect, useState } from "react";

import { useGACheckout } from "../GACheckoutProvider";
import { useGAModals } from "../GAModalsProvider";

import { formatCurrency } from "../../../../utilities/helpers";

import Button from "react-bootstrap/Button";

import { BannerImage } from "../../components/BannerImage";
import { AdditionalInfo } from "../../components/AdditionalInfo";
import { MainDescription } from "../../components/MainDescription";
import { GetTickets } from "../../components/GetStatus/GetTickets";
import { GetPresale } from "../../components/GetStatus/GetPresale";
import { GetSoldout } from "../../components/GetStatus/GetSoldout";
import { AboutSection } from "../../components/AboutSection";
import { Lineup } from "../../components/Lineup";
import { VenueSection } from "../../components/VenueSection";

export default function GAPurchaseContainer({
  selectedFromQuickpicks,
  event,
  isScheduled,
  scheduledTime,
  quickpicks,
  isSoldout,
  selectQuickPick,
  isLoading,
}) {
  const { openCBModal } = useGAModals();
  const { checkout } = useGACheckout();

  const [eventStatus, setEventStatus] = useState("on_sale"); // ['forsale', 'presale', 'soldout']
  const [lowestPrice, setLowestPrice] = useState(null);

  // Testing set event status based on query param 'status'
  useEffect(() => {
    const statusParam = event?.status;
    if (statusParam) {
      switch (statusParam) {
        case "on_sale":
          setEventStatus("on_sale");
          break;
        case "pre_sale":
          setEventStatus("pre_sale");
          break;
        case "sold_out":
          setEventStatus("sold_out");
          break;
        default:
          break;
      }
      return;
    }
  }, [event]);

  useEffect(() => {
    if (selectedFromQuickpicks) {
      console.log("selectedFromQuickpicks....");
      checkout();
    }
  }, [selectedFromQuickpicks]);

  useEffect(() => {
    if (quickpicks && quickpicks.length > 0) {
      const validPrices = quickpicks
        .filter((quickpick) => quickpick && quickpick.price != null)
        .map((quickpick) => quickpick.price);

      if (validPrices.length > 0) {
        const lowest = Math.min(...validPrices);
        setLowestPrice(lowest);
      } else {
        setLowestPrice(null);
      }
    } else {
      setLowestPrice(null);
    }
  }, [quickpicks]);

  return (
    <div className='new-tickets-page purchaseflow-gradient'>
      <div className='sticky-content'>
        <BannerImage event={event} />
        <div className='tablet-desktop-only'>
          <AdditionalInfo
            MTDescription={"Securely stored in your account."}
            BPDescription={"Safe from bots and scalpers."}
          />
        </div>
      </div>

      <div className='tickets_col'>
        <MainDescription event={event} />
        {!isSoldout && (
          <GetTickets
            event={event}
            isScheduled={isScheduled}
            scheduledTime={scheduledTime}
            quickpicks={quickpicks}
            selectQuickPick={selectQuickPick}
            isLoading={isLoading}
          />
        )}
        {true && <GetPresale fixedMobile={true} />}
        {true && <GetSoldout fixedMobile={true} />}
        <AboutSection title='About' summary={event?.summary} />
        <Lineup attractions={event?.attractions} />
        <VenueSection event={event} />
        <div className='mobile-only'>
          <AdditionalInfo
            MTDescription={"Securely stored in your account."}
            BPDescription={"Safe from bots and scalpers."}
          />
        </div>
      </div>
      <div
        className={`get_tickets_mobile ${
          true ? "get_mobile_fixed" : "get_mobile_normal"
        }`}
      >
        <div>
          {lowestPrice && <p>From {formatCurrency(lowestPrice)}</p>}
          <span>No hidden costs, no suprises!</span>
        </div>
        <Button
          onClick={() => openCBModal("Select Ticket")}
          variant='primary'
          size='lg'
          className='m-0'
        >
          Buy Now
        </Button>
      </div>
    </div>
  );
}
